.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-page {
  font-family: "Inter" !important;
  color: black !important;
  margin-bottom: 25px !important;
}

.box-sha {
  box-shadow: 0 0.7px 1.4px #00000012, 0 1.9px 4px #0000000d,
    0 4.5px 10px #0000000d !important;
}

.pnter-select {
  cursor: pointer;
}

.pnter-select a {
  color: black;
}

.pnter-select:hover {
  background-color: rgba(211, 218, 230, 0.25);
}

.title-kyc {
  font-family: "Titillium Web", sans-serif !important;
  font-weight: bold !important;
  font-size: 1.3rem !important;
  color: gray !important;
}

.navgroup {
  position: fixed;
}

.euiListGroupItem-isActive {
  background: rgba(0, 119, 204, 0.2) !important;
  list-style: none !important;
  margin: 5px 0px;
}

.remove-undeline button {
  text-decoration: none !important;
}

.inter {
  font-family: "inter";
  src: url("assets/fonts/inter/Inter-Thin-BETA.otf");
}
