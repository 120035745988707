@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$euiColorPrimary: #00BFB3;


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  outline: none!important;
  font-family: "Inter", BlinkMacSystemFont, Helvetica, Arial, sans-serif!important;
}

.title{
  font-weight: 500!important;
  line-height: 40px;
  text-align: left;
  font-size: 1.5rem!important;
  white-space: nowrap;
  font-family: 'Titillium Web', sans-serif!important;
}

.euiPopover__panel{
  padding: 0!important;
}

.icon-shadow{
  box-shadow: 0 .7px 1.4px #00000012,0 1.9px 4px #0000000d,0 4.5px 10px #0000000d;
}

.title-login{
  font-weight: bold!important;
  line-height: 40px;
  color: black!important;
  font-size: 2.2rem!important;
  white-space: nowrap;
  font-family: 'Titillium Web', sans-serif!important;
  text-align: center;
}

.form {
  padding:1.8rem;

  textarea {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
  }

  input {
      margin-top: 0.5rem;
  }

  .form-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
  }

  .cancel-btn {
      margin-right: 0.7rem;
  }
}

.active-kyc{
  border: 2px solid #00BFB3;
  .euiCard__title{
    color:#00BFB3;
  }
}
.kyc-card{
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
  &:hover{
    border-color: #00BFB3;
    .euiCard__title{
      color:#807d7d;
    }
  }
}

.custom-select{
  background: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    background: rgb(242, 242, 242);;
  }
}
